import dayjs from 'dayjs';
export const getUUid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//  格式化文件大小
export const formatFileSize = (val: string | number): string => {
  if (val == null || val === '' || !val) {
    return '0 Bytes';
  }
  const unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  const srcsize = Math.abs(parseFloat(String(val)));
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  const size = (srcsize / Math.pow(1024, index)).toFixed(2);
  //  保留的小数位数
  return size + unitArr[index];
};

export const parseCronExpression = (cronExpression: string): number => {
  // const handleDate = (str: string) => {
  //   if (str.includes('*')) {
  //     return 1;
  //   } else if (str.includes('-')) {
  //     return str.split('-');
  //   } else if (str.includes('/')) {
  //     return str.split('/');
  //   } else if (str.includes(',')) {
  //     return str.split(',');
  //   }
  // };
  const parts = cronExpression.split(' ');
  const minute = parseInt(parts[0]);
  const hour = parseInt(parts[1]);
  const dayOfMonth = parseInt(parts[2]);
  const month = parseInt(parts[3]);
  const dayOfWeek = parseInt(parts[4]);

  const now = new Date();
  const year = now.getFullYear();

  let nextDate = new Date(year, month - 1, dayOfMonth, hour, minute);

  if (nextDate < now) {
    nextDate = new Date(year + 1, month - 1, dayOfMonth, hour, minute);
  }

  while (nextDate.getDay() !== dayOfWeek) {
    nextDate.setDate(nextDate.getDate() + 1);
  }

  return nextDate.getTime();
};

export const cronExpressionCount = (cronExpression: string): number => {
  const parts = cronExpression.split(' ');
  const minute = parseInt(parts[0]);
  const hour = parseInt(parts[1]);
  const dayOfMonth = parseInt(parts[2]);
  const month = parseInt(parts[3]);
  const dayOfWeek = parseInt(parts[4]);

  const now = new Date();
  const year = now.getFullYear();

  let nextDate = new Date(year, month - 1, dayOfMonth, hour, minute);

  if (nextDate < now) {
    nextDate = new Date(year + 1, month - 1, dayOfMonth, hour, minute);
  }

  while (nextDate.getDay() !== dayOfWeek) {
    nextDate.setDate(nextDate.getDate() + 1);
  }

  return nextDate.getTime();
};
export const checkStorage = async (): Promise<void> => {
  if (!navigator?.storage?.estimate) {
    console.log('不支持检查');
    return;
  }
  const res = await navigator.storage.estimate();
  // quota.usage -> 已使用的字节数。
  // quota.quota -> 可用最大字节数。
  const { usage = 0, quota = 0 } = res;
  if (usage && quota) {
    const percentageUsed = (usage / quota) * 100;
    const remaining = quota - usage;
    console.log(`可用储存已使用${percentageUsed}%。`);
    console.log(`最多可再写入`, formatFileSize(remaining));
    console.log(`已使用的字节数。`, formatFileSize(usage));
    console.log(`可用最大字节数。`, formatFileSize(quota));
  } else {
    console.log('不支持检查');
  }
};

export const downloadFile = (data: any, fileName: string): void => {
  // // data为blob格式
  const blob = new Blob([data], {
    type: 'text/javascript',
  });
  // const downloadElement = document.createElement('a');
  // const href = window.URL.createObjectURL(blob);
  // downloadElement.href = href;
  // downloadElement.download = fileName;
  // document.body.appendChild(downloadElement);
  // downloadElement.click();
  // document.body.removeChild(downloadElement);
  // window.URL.revokeObjectURL(href);

  const reader = new FileReader();
  // 传入被读取的blob对象
  reader.readAsDataURL(blob);
  // 读取完成的回调事件
  reader.onload = e => {
    const a = document.createElement('a');
    a.download = fileName;
    a.style.display = 'none';
    // 生成的base64编码
    const url = reader.result;
    a.href = url as string;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
};
