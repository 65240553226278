import './App.css';
import { useRoutes } from 'react-router-dom';
import routes from './routes/index';
import { useEffect } from 'react';
import { checkStorage } from './modules/tools';

function App(): JSX.Element {
  const element = useRoutes(routes);
  useEffect(() => {
    checkStorage();
  }, []);
  return <div className="App">{element}</div>;
}

export default App;
