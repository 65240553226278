import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

function LoadingPage(): JSX.Element {
  return <div>loading...</div>;
}
const Home = lazy(async () => await import('../pages/Home'));
const AddPlan = lazy(async () => await import('../pages/AddPlan'));
const EditPlan = lazy(async () => await import('../pages/EditPlan'));
const ShowPlan = lazy(async () => await import('../pages/ShowPlan'));
const PlanDetail = lazy(async () => await import('../pages/PlanDetail'));
const Test = lazy(async () => await import('../pages/Test'));
const routes = [
  {
    path: '/Home',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: '/PlanDetail',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <PlanDetail />
      </Suspense>
    ),
  },
  {
    path: '/ShowPlan',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <ShowPlan />
      </Suspense>
    ),
  },
  {
    path: '/AddPlan',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <AddPlan />
      </Suspense>
    ),
  },
  {
    path: '/EditPlan',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <EditPlan />
      </Suspense>
    ),
  },
  {
    path: '/Test',
    element: (
      <Suspense fallback={<LoadingPage />}>
        <Test />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: <Navigate to="/Home" />,
  },
];

export default routes;
